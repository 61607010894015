import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { Label } from '@UX/Forms/Label/Label';

interface FieldsetProps extends ClassNameProps {
  label: string;
  name: string;
}

export const Fieldset: React.FC<FieldsetProps> = ({ name, className, children, label }) => (
  <div className={className}>
    <Label
      label={label}
      name={name}
    >
      {label}
    </Label>
    {children}
  </div>
);
